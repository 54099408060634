.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#navbar, #footer {
  background: #232f3e;
  padding: 0 4rem;
}

ul.menu-list {
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0;
}

ul.menu-list > li {
  padding: 13px 4px 9px;
}

ul.menu-list > li a, ul.menu-list > li div {
  float: left;
  color: #ffffff;
  line-height: 20px;
}

ul.menu-list > li a, ul.menu-list > li a:hover {
  cursor: pointer;
  text-decoration: none;
}

ul.menu-list > li.title > div {
  font-style: italic;
}

ul.menu-list .selected {
  font-weight: bold;
}

ul.menu-list > li.ml-auto {
  margin-left: auto;
}

#footer p {
  color: #fff;
}

#footer a {
  color: #fff;
  font-weight: bold;
}

.font-white {
  color:  #ffffff;
  line-height: 20px;

}
.font-grey {
  color:  grey;
  line-height: 20px;

}
.footercss {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}
.p-5 {
  padding: 5%;
}
.m-5 {
  margin: 5%;
}
.p-1 {
  padding: 1%;
}
.m-1 {
  margin: 1%;
}
.p-05 {
  padding: 0.5%;
}
.m-05 {
  margin: 0.5%;
}
.ml-1 {
  margin-left: 1%;
}
.color-grey {
  color: grey;
}
.color-green {
  color: green;
}
.cursor-pointer {
  cursor: pointer;
  transition: transform .3s ease-out;
  display: inline-block;

}

.cursor-pointer:hover {
  transform: translate(0, -5px);

}